import { DocumentViewingFormData } from '../../DocumentViewingForm.types'
import { AccordionContent, AccordionWrapper, DocWrapper } from './DocumentViewingItem.styles'
import { DocumentViewingDialogTrigger, DocumentViewingItemProps } from './DocumentViewingItem.types'
import { DocumentRemarkItem } from './components/DocumentRemarkItem'
import { FileItem } from './components/FileItem'
import {
  Download as DownloadIcon,
  Delete as DeleteIcon,
  AttachFile as AttachIcon,
  Person as PersonIcon,
  EventNote as CalendarIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack, Typography } from '@mui/material'
import {
  prescriptionsApi,
  useDeleteEliminationResultsMutation,
  useGetRemarksByActIdQuery,
  useUploadFileMutation,
} from 'api/prescriptions'
import { useFormikContext } from 'formik'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import {
  FileLabel,
  HiddenInput,
} from 'pages/Prescriptions/components/PrescriptionsForm/components/Control/Control.styles'
import { RemarkMediaItemButton } from 'pages/Remarks/components/RemarkForm/Media/components/RemarkMediaItem/RemarkMediaItem.styles'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { theme } from 'styles/theme'
import { connectNames } from 'utils/connectNames'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'
import { formatDateInInput } from 'utils/dates/formatDateInInput'
import { parseResponseDate } from 'utils/dates/parseResponseDate'
import { parseResponseDateTime } from 'utils/dates/parseResponseDateTime'
import { downloadMedia } from 'utils/downloadMedia'

export const DocumentViewingItem: FC<DocumentViewingItemProps> = ({ doc, viewingOnly, onRemove, setDocWasDeleted }) => {
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString)
  const { values, setFieldValue } = useFormikContext<DocumentViewingFormData>()
  const { externalFilesForCreate, filesIdsToDelete, initialDocs, internalFilesForCreate } = values || {}
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const [uploadFile, { isLoading: isFileUploading, ...uploadFileResponse }] = useUploadFileMutation()
  const [deleteEliminationResults, { isLoading: isActDeleting, ...deleteEliminationResultsResponse }] =
    useDeleteEliminationResultsMutation()

  const { number, date, author, created, comment, link, shortcomings } = doc || {}
  const { list, total: remarkTotal } = shortcomings || {}
  const parsedDate = parseResponseDate(date).fullDate
  const { fullDate, time } = parseResponseDateTime(created)

  const { queryData, onScrollWithInfiniteLoad } = useInfiniteScroll({
    api: prescriptionsApi,
    tagName: 'Prescriptions',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: useGetRemarksByActIdQuery,
    arg: {
      projectId,
      prescriptionId,
      actId: doc.id,
    },
    options: {
      skip: !isAccordionOpen,
    },
  })
  const { data = [] } = queryData.data || {}
  const { isLoading } = queryData || {}

  const onDownload = () => {
    downloadMedia(link)
  }

  const onAttachFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0]
    if (!file) return
    setFieldValue('externalFilesForCreate', {
      ...values.externalFilesForCreate,
      [doc.id]: [...(values.externalFilesForCreate[doc.id] || []), file],
    })
    uploadFile({ type: 'COMPLETION_ACT', file })
    e.target.value = ''
  }

  const onRemoveActClick = () => {
    setConfirmDialogTrigger('deleteDoc')
    openConfirm()
  }

  const changeAccordionDisplaying = () => {
    setIsAccordionOpen((prev) => !prev)
  }

  const onDownloadFile = (file?: File | null, link?: string) => {
    if (file) {
      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.download = file.name
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
    if (link) downloadMedia(link)
  }

  const onRemoveNewFile = (index: number) => {
    const { externalFilesForCreate, internalFilesForCreate } = values || {}

    let localExternalFilesForCreate = externalFilesForCreate
    localExternalFilesForCreate = {
      ...externalFilesForCreate,
      [doc.id]: [...(localExternalFilesForCreate[doc.id] || [])].filter((file, rootIndex) => rootIndex !== index),
    }

    if (!localExternalFilesForCreate[doc.id].length) delete localExternalFilesForCreate[doc.id]
    setFieldValue('externalFilesForCreate', localExternalFilesForCreate)

    let localInternalFilesForCreate = internalFilesForCreate
    localInternalFilesForCreate = {
      ...values.internalFilesForCreate,
      [doc.id]: [...(localInternalFilesForCreate[doc.id] || [])].filter((file, rootIndex) => rootIndex !== index),
    }

    if (!localInternalFilesForCreate[doc.id].length) delete localInternalFilesForCreate[doc.id]
    setFieldValue('internalFilesForCreate', localInternalFilesForCreate)

    // ещё запрос на удаление с сервера
  }

  const onRemoveExistedFile = (fileId: string) => {
    const { initialDocs, filesIdsToDelete } = values || {}

    setFieldValue('filesIdsToDelete', {
      ...filesIdsToDelete,
      [doc.id]: [...(filesIdsToDelete[doc.id] || []), fileId],
    })

    let localInitialDocs = [...initialDocs]
    let localDocIndex = localInitialDocs.findIndex((rootDoc) => rootDoc.id === doc.id)
    let localDoc = { ...localInitialDocs[localDocIndex] }

    localDoc.attachments = localDoc.attachments.filter((file) => String(file.id) !== fileId)
    localInitialDocs[localDocIndex] = localDoc

    setFieldValue('initialDocs', localInitialDocs)
  }

  const handleDeleteDoc = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        deleteEliminationResults({ projectId, prescriptionId, docIds: [doc.id] })
      }
    },
    [externalFilesForCreate, internalFilesForCreate],
  )

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<DocumentViewingDialogTrigger>('deleteDoc')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    deleteDoc: {
      handleConfirm: handleDeleteDoc,
      title: 'Удалить документ?',
      body: 'Документ будет удален без возможности восстановления.',
    },
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger])

  const handleError = useCallback((showSnackbar = true) => {
    showSnackbar && enqueueSnackbar('Произошла ошибка.', { variant: 'error' })
    // setIsFormLoading(false)
  }, [])

  useMutationHandlers(
    uploadFileResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }
      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      // onChangeTab('view')
      setFieldValue('internalFilesForCreate', {
        ...values.internalFilesForCreate,
        [doc.id]: [...(values.internalFilesForCreate[doc.id] || []), data.data],
      })
    },
    () => handleError(),
  )

  useMutationHandlers(
    deleteEliminationResultsResponse,
    (data) => {
      if (!data.success) {
        enqueueSnackbar(data.description, { variant: 'error' })
        return
      }

      // dispatch(prescriptionsApi.util.invalidateTags([{ type: 'Prescriptions', id: 'ELIMINATION_RESULTS' }]))
      enqueueSnackbar('Документ успешно удалён.', { variant: 'success' })

      const localExternalFilesForCreate = { ...externalFilesForCreate }
      const localInternalFilesForCreate = { ...internalFilesForCreate }
      const localFilesIdsToDelete = { ...filesIdsToDelete }

      delete localExternalFilesForCreate[doc.id]
      delete localInternalFilesForCreate[doc.id]
      delete localFilesIdsToDelete[doc.id]

      setFieldValue('externalFilesForCreate', localExternalFilesForCreate)
      setFieldValue('internalFilesForCreate', localInternalFilesForCreate)
      setFieldValue('filesIdsToDelete', localFilesIdsToDelete)

      onRemove()

      setDocWasDeleted(true)
      // setIsFormLoading(false)
    },
    () => handleError(),
  )

  return (
    <DocWrapper spacing={2}>
      <Stack spacing={1}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant='h6' fontSize={16} color={theme.palette.text.dark}>
            № {number} от {parsedDate}
          </Typography>

          <Stack spacing={1} direction={'row'}>
            <RemarkMediaItemButton onClick={onDownload} type='button'>
              <DownloadIcon color='primary' fontSize={'medium'} />
            </RemarkMediaItemButton>

            {!viewingOnly && (
              <>
                <FileLabel htmlFor={`uploadFile-act${doc.id}`}>
                  <RemarkMediaItemButton type='button' style={{ pointerEvents: 'none' }}>
                    <AttachIcon color='primary' fontSize={'medium'} />
                  </RemarkMediaItemButton>
                </FileLabel>
                <HiddenInput
                  onChange={onAttachFile}
                  type='file'
                  id={`uploadFile-act${doc.id}`}
                  className='none'
                  accept='.docx, .pdf'
                />

                <RemarkMediaItemButton onClick={onRemoveActClick} type='button'>
                  <DeleteIcon color='error' fontSize={'medium'} />
                </RemarkMediaItemButton>
              </>
            )}
          </Stack>
        </Stack>

        <Stack direction={'row'} spacing={1}>
          <PersonIcon color={'secondary'} fontSize={'small'} />
          <Typography variant='body2' fontSize={12}>
            {connectNames(author, true)}
          </Typography>
        </Stack>

        <Stack direction={'row'} spacing={1}>
          <CalendarIcon color={'secondary'} fontSize={'small'} />
          <Typography variant='body2' fontSize={12}>
            {fullDate} {time}
          </Typography>
        </Stack>
      </Stack>

      <Stack>
        <AccordionWrapper onClick={changeAccordionDisplaying}>
          <Typography variant='body1' fontSize={14} color={theme.palette.primary.main}>
            Замечания ({remarkTotal})
          </Typography>

          {isLoading && <CircularProgress size={20} />}

          {!isLoading &&
            (isAccordionOpen ? (
              <RemoveIcon color={'primary'} fontSize={'medium'} />
            ) : (
              <AddIcon color={'primary'} fontSize={'medium'} />
            ))}
        </AccordionWrapper>

        <AccordionContent
          onScroll={onScrollWithInfiniteLoad}
          spacing={1}
          isOpen={isAccordionOpen && !!data.length}
          isScrollable={isAccordionOpen && data.length > 3}
        >
          {data.map((remark) => (
            <DocumentRemarkItem key={remark.id} remark={remark} isSelectable={false} />
          ))}
        </AccordionContent>
      </Stack>

      {comment && (
        <Stack spacing={1}>
          <Typography variant='body2' fontSize={12} color={theme.palette.text.secondary}>
            Комментарий:
          </Typography>

          <Typography variant='body2' color={theme.palette.text.dark}>
            {comment}
          </Typography>
        </Stack>
      )}

      <Stack spacing={1}>
        {(values.externalFilesForCreate[doc.id] || []).map((file, index) => {
          const name = file.name
          const splittedName = name.split('.')
          const format = splittedName[splittedName.length - 1]

          return (
            <FileItem
              name={name}
              format={format}
              link={URL.createObjectURL(file)}
              onDownload={() => onDownloadFile(file)}
              onRemoveFile={() => onRemoveNewFile(index)}
            />
          )
        })}
        {doc.attachments.map((file, index) => {
          const name = file.link.split('/').slice(-1)[0]
          const splittedName = name.split('.')
          const format = splittedName[splittedName.length - 1]

          return (
            <FileItem
              name={name}
              format={format}
              link={file.link}
              onDownload={() => onDownloadFile(undefined, file.link)}
              onRemoveFile={() => onRemoveExistedFile(String(file.id))}
            />
          )
        })}
      </Stack>

      <ConfirmDialog />
    </DocWrapper>
  )
}

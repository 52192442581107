import {
  PrescriptionAutofilledData,
  PrescriptionFormData,
  PrescriptionLocationState,
} from '../../PrescriptionsForm.types'
import { PdfViewerPopup } from '../PdfViewerPopup'
import { FileLabel, HiddenInput, Wrapper } from './Control.styles'
import { ControlProps, automaticDateEndInfo } from './Control.types'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  EventAvailable as EventAvailableIcon,
  InsertDriveFile as FileIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material'
import { Stack, Switch, Typography } from '@mui/material'
import {
  useGetDropdownPrescriptionContractorsQuery,
  useGetDropdownSendersQuery,
  useLazyGetDropdownExecutionControlQuery,
  useLazyGetDropdownReceiversQuery,
} from 'api/prescriptions'
import { Button } from 'components/Button'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { PdfViewerProps } from 'components/PdfViewer'
import { Tooltip } from 'components/Tooltip'
import { FieldArray, useFormikContext } from 'formik'
import { useFirstRender } from 'hooks/useFirstRender'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { FC, useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { theme } from 'styles/theme'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { downloadMedia } from 'utils/downloadMedia'

export const Control: FC<ControlProps> = ({ viewingOnly, setIsLocalValid, formMode, setAutofilledData }) => {
  const { values, setFieldValue, initialValues, isValid } = useFormikContext<PrescriptionFormData>()
  const { projectId: projectIdString, prescriptionId: prescriptionIdString } = useParams()
  const projectId = Number(projectIdString)
  const prescriptionId = Number(prescriptionIdString)
  const [isDateDoneRequired, setIsDateDoneRequired] = useState<boolean>(false)
  const isAddingMode = formMode === 'add'
  const [isDateDoneFocused, setIsDateDoneFocused] = useState(false)

  const [openedPdfData, setOpenedPdfData] = useState<PdfViewerProps | null>(null)

  const { data: sendersDropdown } = useGetDropdownSendersQuery({ projectId })
  const { data: contractorsDropdown } = useGetDropdownPrescriptionContractorsQuery({ projectId })

  const [receiversDropdownRequest, { data: receiversDropdown }] = useLazyGetDropdownReceiversQuery()
  const [executionControlDropdownRequest, { data: executionControlDropdown }] =
    useLazyGetDropdownExecutionControlQuery()

  const firstRender = useFirstRender()

  const [secondRender, setSecondRender] = useState<boolean>(false)

  useEffect(() => {
    const { contractor } = values

    if (!firstRender && secondRender) {
      setFieldValue('receiver', null)
      setFieldValue('executionControl', null)
      setIsLocalValid(false)
    }

    if (!firstRender) setSecondRender(true)

    if (contractor?.value) {
      receiversDropdownRequest({
        projectId,
        contractor: String(contractor.id),
      })
      executionControlDropdownRequest({
        projectId,
        contractor: String(contractor.id),
      })
    }
  }, [values.contractor])

  useEffect(() => {
    if (viewingOnly) return

    if (formatDateForServer(values.dateDone) && !isDateDoneFocused) {
      setFieldValue('status', 'COMPLETE')
    }
  }, [values.dateDone])

  useEffect(() => {
    if (viewingOnly) return

    if (values.status === 'COMPLETE') {
      if (!values.dateDone) {
        setFieldValue('dateDone', new Date())
      }
      setIsDateDoneRequired(true)
    } else {
      // setFieldValue('dateDone', null)
      setIsDateDoneRequired(false)
    }
  }, [values.status])

  // contractor autofill
  useEffect(() => {
    const contractorsList = contractorsDropdown?.data || []
    const firstContractor = contractorsList[0]
    const { id, text } = firstContractor || {}

    if (isAddingMode && contractorsList.length === 1 && !values.contractor) {
      const contractorData = {
        id: id,
        value: text,
      }
      setFieldValue('contractor', contractorData)

      setAutofilledData((prev: PrescriptionAutofilledData) => ({ ...prev, contractor: contractorData }))
    }
  }, [contractorsDropdown, initialValues])

  const onAddFile = (e: React.ChangeEvent<HTMLInputElement>, insert: (index: number, value: File) => void) => {
    const file = e.target.files![0]
    if (!file) return
    insert(0, file)
    e.target.value = ''
  }

  const downloadFile = (file?: File | null, link?: string) => {
    if (file) {
      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.download = file.name
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
    if (link) downloadMedia(link)
  }

  const openViewer = (fileUrl: string, title: string, onDownload: () => void, onDelete: () => void) => {
    const onDeleteWithClose = () => {
      onDelete()
      setOpenedPdfData(null)
    }

    setOpenedPdfData({
      fileUrl,
      title,
      onDownload,
      onDelete: onDeleteWithClose,
    })
  }

  const onViewerClose = () => {
    setOpenedPdfData(null)
  }

  const changeDateDoneFocus = (value: boolean) => {
    setIsDateDoneFocused(value)
  }

  const fileBlock = (name: string, onDownload: () => void, onDelete: () => void, onViewerOpen: () => void) => {
    const splittedName = name.split('.')
    const format = splittedName[splittedName.length - 1]

    return (
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        <Stack
          onClick={format === 'pdf' ? onViewerOpen : undefined}
          style={{ cursor: format === 'pdf' ? 'pointer' : 'initial' }}
          direction={'row'}
          alignItems={'center'}
          spacing={0.5}
        >
          <FileIcon fontSize={'medium'} color={'primary'} />
          <Typography variant={'body2'} color={theme.palette.primary.main} lineHeight={'inherit'}>
            {name}
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <DownloadIcon
            onClick={onDownload}
            style={{ fill: theme.palette.primary.main, cursor: 'pointer', marginTop: '1px' }}
          />
          {!viewingOnly && (
            <DeleteIcon onClick={onDelete} style={{ fill: theme.palette.error.main, cursor: 'pointer' }} />
          )}
        </Stack>
      </Stack>
    )
  }

  return (
    <Wrapper>
      <Stack spacing={3}>
        <Stack>
          <Typography marginBottom={1.5} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
            Строительный контроль
          </Typography>
          <CustomSelect
            name='sender'
            list={
              sendersDropdown?.data?.map((item) => ({
                ...item,
                group: item.data === 'engineerQC' ? 'Инженеры СК:' : 'Представители участников проекта:',
              })) || []
            }
            isSearch={true}
            placeholder='Выберите из списка'
            label='Предписание выдал'
            isSubtext={true}
            style={{
              textAlign: 'start',
            }}
            readOnly={viewingOnly}
            isRequired={true}
            isGroup={true}
          />
        </Stack>
        <Stack>
          <Typography marginBottom={2} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
            Подрядчик
          </Typography>
          <Stack spacing={1.5}>
            <CustomSelect
              name='contractor'
              list={contractorsDropdown?.data || []}
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              label='Подрядчик'
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly}
              isRequired={true}
            />
            <CustomSelect
              name='receiver'
              list={
                receiversDropdown?.data.map(({ id, text, subtext, data }) => ({
                  id,
                  text,
                  subtext: `${subtext || '—'}, ${data?.shortName || '—'}`,
                })) || []
              }
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              label='Предписание получил'
              isSubtext={true}
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly}
              isRequired={true}
              isDisabled={!values?.contractor?.value}
            />
            <CustomSelect
              name='executionControl'
              list={
                executionControlDropdown?.data.map(({ id, text, subtext, data }) => ({
                  id,
                  text,
                  subtext: `${subtext || '—'}, ${data?.shortName || '—'}`,
                })) || []
              }
              isSearch={true}
              placeholder={!viewingOnly ? 'Выберите из списка' : ''}
              label='Контроль за устранением нарушений'
              isSubtext={true}
              style={{
                textAlign: 'start',
              }}
              readOnly={viewingOnly}
              isRequired={true}
              isDisabled={!values?.contractor?.value}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography marginBottom={2} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
            Сроки
          </Typography>
          <Stack spacing={1.5}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} marginRight={-1.5}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant='body2' color={theme.palette.text.dark}>
                  Автозаполнение Уст. даты устранения
                </Typography>

                <Tooltip
                  title={automaticDateEndInfo}
                  bgColor={theme.palette.bg.white}
                  textColor={theme.palette.text.dark}
                >
                  <InfoIcon fontSize='inherit' color='action' />
                </Tooltip>
              </Stack>

              <FieldForm
                version='switch'
                name=''
                checkboxData={{
                  checked: values.automaticDateEnd,
                  onChange: (e) => setFieldValue('automaticDateEnd', e.target.checked),
                  label: '',
                }}
              />
            </Stack>
            <FieldItem
              title='Установленная дата устранения'
              icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
              isRequired={true}
            >
              <FieldForm
                version='date'
                name='dateEnd'
                placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                helperText=''
                dataValue={values.dateEnd}
                onDataChange={(value: Date | null) => setFieldValue('dateEnd', value)}
                style={{ maxWidth: '144px', width: '100%' }}
                dateFieldProps={{
                  minDate: values.dateStart || undefined,
                  readOnly: viewingOnly,
                  disabled: values.automaticDateEnd,
                }}
              />
            </FieldItem>
            {!!prescriptionId && (
              <FieldItem
                title='Фактическая дата устранения'
                icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
                isRequired={isDateDoneRequired}
              >
                <FieldForm
                  version='date'
                  name='dateDone'
                  placeholder={!viewingOnly ? 'дд.мм.гггг' : ' '}
                  helperText=''
                  dataValue={values.dateDone}
                  onFocus={() => changeDateDoneFocus(true)}
                  onBlur={() => changeDateDoneFocus(false)}
                  onDataChange={(value: Date | null) => setFieldValue('dateDone', value)}
                  style={{ maxWidth: '144px', width: '100%' }}
                  dateFieldProps={{
                    minDate: values.dateStart || undefined,
                    maxDate: new Date() || undefined,
                    readOnly: viewingOnly,
                  }}
                />
              </FieldItem>
            )}
          </Stack>
        </Stack>
        <Stack>
          <FieldArray
            name='filesForCreate'
            render={({ insert, remove }) => (
              <>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
                    Документы
                  </Typography>
                  {!viewingOnly && (
                    <>
                      <FileLabel isDisabled={initialValues.status !== 'CREATED'} htmlFor={`uploadFile`}>
                        <Button
                          disabled={initialValues.status !== 'CREATED'}
                          icon={true}
                          color='secondary'
                          variant='outlined'
                          style={{ pointerEvents: 'none' }}
                        >
                          <AddIcon />
                        </Button>
                      </FileLabel>
                      <HiddenInput
                        onChange={(e) => onAddFile(e, insert)}
                        type='file'
                        id={`uploadFile`}
                        className='none'
                        accept='.docx, .pdf'
                      />
                    </>
                  )}
                </Stack>
                <Stack spacing={1.5} marginTop={3} alignItems={'flex-start'}>
                  {values.filesForCreate.length || values.files.length ? (
                    <>
                      {values.filesForCreate.map((item, index) => {
                        // {values.filesForCreate.reverse().map((item, index) => (
                        const url = URL.createObjectURL(item)

                        const onDownload = () => downloadFile(item)
                        const onDelete = () => remove(index)
                        const onDrawerOpen = () => {
                          openViewer(url, item.name, onDownload, onDelete)
                        }

                        return fileBlock(item.name, onDownload, onDelete, onDrawerOpen)
                      })}
                      {values.files.length ? (
                        <FieldArray
                          name='files'
                          render={({ insert, remove }) => (
                            <>
                              {values.files.map((item, index) => {
                                const name = item.link.split('/').slice(-1)[0]
                                const onDownload = () => downloadFile(null, item.link)
                                const onDelete = () => {
                                  setFieldValue('filesIdsToDelete', [...values.filesIdsToDelete, item.id])
                                  remove(index)
                                }
                                const onDrawerOpen = () => {
                                  openViewer(item.link, name, onDownload, onDelete)
                                }

                                return fileBlock(name, onDownload, onDelete, onDrawerOpen)
                              })}
                            </>
                          )}
                        />
                      ) : null}
                    </>
                  ) : (
                    '—'
                  )}
                </Stack>
              </>
            )}
          />
        </Stack>
      </Stack>

      <PdfViewerPopup opened={openedPdfData !== null} viewerProps={openedPdfData} onViewerClose={onViewerClose} />
    </Wrapper>
  )
}

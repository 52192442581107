import { validationProject } from '../../Administration/components/ProjectDrawerForm/validation'
import { ProjectFormData, ProjectManagementTabs } from './ProjectManagement.types'
import { ManagementForm } from './components/ManagementForm'
import { ManagementLegend } from './components/ManagementLegend'
import { MapForm } from './components/MapForm'
import { Stack } from '@mui/material'
import {
  useCreateProjectMutation,
  useDeleteImagesFromProjectMutation,
  useEditProjectMutation,
  useGetProjectDashboardQuery,
  useUploadImagesToProjectMutation,
} from 'api/projects'
import { ProjectBaseResponse, ProjectBaseWithImagesResponse, ProjectRequest } from 'api/projects/api.types'
import { ProjectAddressForRequest, ProjectResponsiblePersons } from 'api/projects/types'
import { DirtyFormForGlobalConfirm } from 'core/types/global'
import { ProjectFinType, ProjectStatus, ProjectType } from 'core/types/project'
import { Form, FormikProvider } from 'formik'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { useFilteredEmployees } from 'hooks/useFilteredEmployees'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { oldFormatDateForServer, oldFormatToDate } from 'utils/dates/oldFormatToDate'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const ProjectManagement: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { projectId: projectIdString } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const projectId = Number(projectIdString)

  useBreadcrumbs(projectId ? [{ title: 'Управление проектом' }] : [{ title: 'Создание проекта' }])

  const { data: projectRes, isFetching } = useGetProjectDashboardQuery(
    {
      id: projectId!,
    },
    {
      skip: !projectId,
    },
  )
  const { data: projectData } = projectRes || {}
  const { project, addressData, responsiblePersons, images } = projectData || {}

  const { id, shortName, fullName, startDate, endDate, status, type, finType } = project || {}

  const { address, latitude, longitude, region } = addressData || {}
  const {
    clientDirector: initialClientDirector,
    clientCurator: initialClientCurator,
    contractors: initialContractors,
  } = responsiblePersons || {}

  const [currentTab, setCurrentTab] = useState<ProjectManagementTabs>('info')
  const onTabChange = (value: ProjectManagementTabs) => {
    value !== currentTab && setCurrentTab(value)
  }

  const initialValues: ProjectFormData = useMemo(
    () => ({
      shortName: shortName || '',
      fullName: fullName || '',
      status: status || '',
      type: type || '',
      finType: finType || '',
      startDate: oldFormatToDate(startDate),
      endDate: oldFormatToDate(endDate),
      initialImages: images || [],
      imagesForCreate: [],
      imagesIdsToDelete: [],
      addressData: {
        address: { value: address || '', unrestricted_value: '', data: '' },
        latitude: latitude || '',
        longitude: longitude || '',
        region: region || '',
      },
      responsiblePersons: {
        client: {
          company: initialClientDirector?.company,
          directorData: initialClientDirector?.id || initialClientDirector?.customName || '',
          curatorData: initialClientCurator?.id || initialClientCurator?.customName || '',
        },
        contractorsArray: initialContractors?.length
          ? initialContractors?.map((item: any) => {
              return {
                company: item?.contractor?.company || item?.contractor?.contractor?.shortName || '',
                data:
                  item?.responsible?.representative?.fullName ||
                  (item?.responsible?.user &&
                    `${item?.responsible.user?.lastName || ''} ${item?.responsible.user?.firstName || ''} ${
                      item?.responsible.user?.middleName || ''
                    }`) ||
                  '',
              }
            })
          : [{ data: '', company: '' }],
      },
    }),
    [projectData, responsiblePersons],
  )

  const { clientsAndAdmins, contractors } = useFilteredEmployees()

  const [createProject, createProjectResponse] = useCreateProjectMutation()
  const [editProject, editProjectResponse] = useEditProjectMutation()
  const [uploadImagesToProject, uploadImagesToProjectResponse] = useUploadImagesToProjectMutation()
  const [deleteImagesFromProject, deleteImagesFromProjectResponse] = useDeleteImagesFromProjectMutation()

  const onSubmit = useCallback(
    (values: ProjectFormData) => {
      const { client, contractorsArray } = values.responsiblePersons || {}
      const { address, ...otherAddressData } = values.addressData || {}

      const { directorData, curatorData, company: clientCompany } = client

      const responsiblePersons: ProjectResponsiblePersons<any> = {
        clientDirector: {
          id: typeof directorData === 'number' ? directorData : (null as unknown as number),
          name:
            (typeof directorData === 'number'
              ? clientsAndAdmins.find((client) => client.id === directorData)?.name
              : directorData) || '',
          company: clientCompany || '',
        },
        clientCurator: {
          id: typeof curatorData === 'number' ? curatorData : (null as unknown as number),
          name:
            (typeof curatorData === 'number'
              ? clientsAndAdmins.find((client) => client.id === curatorData)?.name
              : curatorData) || '',
          company: clientCompany || '',
        },
        contractors:
          contractorsArray[0].data || contractorsArray[0].company
            ? contractorsArray.map((item, i) => {
                // @ts-ignore
                let contractorData = item.company ? [item.company?.id || item.company?.text, item.company?.data] : [] // "contractor" | "company"
                if (item.company && !contractorData[1]) contractorData = [item.company]

                // @ts-ignore
                let responsibleData = item.data ? [item.data?.id, item.data?.data] : []
                if (item.data && !responsibleData[1]) responsibleData = [item.data]

                return {
                  contractor: contractorData.length
                    ? {
                        company: contractorData[1] === 'company' ? contractorData[0] : null,
                        contractor: contractorData[1] === 'projectMember' ? Number(contractorData[0]) : null,
                        custom: !contractorData[1] && contractorData.length ? contractorData[0] : null,
                      }
                    : null,
                  responsible: responsibleData.length
                    ? {
                        representative: responsibleData[1] === 'representative' ? responsibleData[0] : null,
                        user: responsibleData[1] === 'user' ? Number(responsibleData[0]) : null,
                        custom: !responsibleData[1] && responsibleData.length ? responsibleData[0] : null,
                      }
                    : null,
                }
              })
            : [],
      }

      const addressData: ProjectAddressForRequest = {
        ...otherAddressData,
        region: values.addressData?.region || null,
        address: address?.value || '',
      }

      const dataForRequest: ProjectRequest = {
        fullName: values.fullName,
        shortName: values.shortName.trim(),
        startDate: oldFormatDateForServer(values.startDate),
        endDate: oldFormatDateForServer(values.endDate),
        status: values.status as ProjectStatus,
        type: values.type as ProjectType,
        finType: values.finType as ProjectFinType,
        addressData,
        responsiblePersons,
      }

      if (id) {
        editProject({
          id,
          ...dataForRequest,
        })
      } else {
        createProject(dataForRequest)
      }
    },
    [project, clientsAndAdmins],
  )

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationProject,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, isValid, isSubmitting, dirty } = formik

  // set global dirty for confirm dialogs on menu items click
  useEffect(() => {
    const dirtyFormType: DirtyFormForGlobalConfirm = dirty ? 'project' : ''
    localStorage.setItem('dirtyForm', dirtyFormType)
  }, [dirty])

  //Todo url param for navigate to home page
  const navigateToSourcePath = () => {
    navigate('/administration/projects')
  }

  useMutationHandlers(createProjectResponse, (data: ProjectBaseResponse) => {
    if (data.success) {
      enqueueSnackbar('Проект успешно добавлен.', { variant: 'success' })

      if (!values.imagesForCreate.length) {
        navigateToSourcePath()
        return
      }

      uploadImagesToProject({
        id: data.data.id,
        file: [...values.imagesForCreate].reverse(),
      })
    } else {
      enqueueSnackbar('Проект с таким наименованием уже существует.', { variant: 'error' })
    }
  })

  useMutationHandlers(editProjectResponse, (data: ProjectBaseResponse) => {
    if (data.success) {
      if (values.imagesIdsToDelete.length) {
        deleteImagesFromProject({
          id: data.data.id,
          img: values.imagesIdsToDelete,
        })
        return
      }

      if (values.imagesForCreate.length) {
        uploadImagesToProject({
          id: data.data.id,
          file: values.imagesForCreate,
        })
      }

      enqueueSnackbar('Проект успешно изменен.', { variant: 'success' })
      navigateToSourcePath()
    } else {
      enqueueSnackbar('Проект с таким наименованием уже существует.', { variant: 'error' })
    }
  })

  useMutationHandlers(deleteImagesFromProjectResponse, (data: ProjectBaseWithImagesResponse) => {
    if (values.imagesForCreate.length) {
      uploadImagesToProject({
        id: data.data.id,
        file: values.imagesForCreate,
      })
    }

    navigateToSourcePath()
  })

  useMutationHandlers(uploadImagesToProjectResponse, () => {
    navigateToSourcePath()
  })

  useEffect(() => {
    if (isSubmitting && currentTab === 'additional' && !isValid) {
      enqueueSnackbar('Не заполнены обязательные поля на вкладке “О проекте”.', { variant: 'error' })
    }
  }, [isSubmitting])

  const ContentByTab: Record<ProjectManagementTabs, ReactNode> = {
    info: <ManagementForm />,
    additional: <MapForm />,
  }

  return (
    <FormikProvider value={formik}>
      <Stack flex={1} component={Form}>
        <ManagementLegend tabValue={currentTab} onTabChange={onTabChange} navigateToSourcePath={navigateToSourcePath} />

        {ContentByTab[currentTab]}
      </Stack>
    </FormikProvider>
  )
}

import {
  IntegrationModuleCard,
  IntegrationModuleStatusWrapper,
  ProjectIntegrationWrapper,
} from './ProjectIntegrationDrawer.styles'
import {
  IntergationModuleCard,
  PragmaModule,
  ProjectDialogTrigger,
  ProjectIntegrationDrawerProps,
  ProjectSliderType,
  integrationModuleStatusColor,
  integrationModuleStatusRuByEn,
  pragmaModuleIcon,
  pragmaModuleLabel,
} from './ProjectIntegrationDrawer.types'
import { IntegrationProjectSlide } from './components/IntegrationProjectSlide'
import { IntegrationRequestSlide } from './components/IntegrationRequestSlide'
import { Drawer, Stack, Typography } from '@mui/material'
import { useGetIntegrationStatusByProjectQuery } from 'api/integrations'
import { Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { DrawerTopBar } from 'pages/Administration/References/ReferenceContent/ReferenceDrawers/EditAndAddDrawer/EditAndAddDrawer.styles'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { theme } from 'styles/theme'

export const ProjectIntegrationDrawer: FC<ProjectIntegrationDrawerProps> = ({ isOpen, onClose, project }) => {
  const { id: projectId } = project
  const { data: projectIntegrationStatus, isFetching } = useGetIntegrationStatusByProjectQuery(
    { projectId },
    { skip: !isOpen },
  )
  const [openedSlider, setOpenedSlider] = useState<ProjectSliderType | null>(null)
  const [selectedModule, setSelectedModule] = useState<PragmaModule>('PragmaDesign')

  const intergationModuleCards = useMemo((): IntergationModuleCard[] => {
    let result = projectIntegrationStatus?.data.global.map((item) => ({
      value: item.module,
      label: pragmaModuleLabel[item.module],
      status: item.status,
      icon: pragmaModuleIcon[item.module],
    }))

    return result || []
  }, [projectIntegrationStatus])

  const onModuleClick = (moduleCard: IntergationModuleCard) => {
    const sliderType = moduleCard.status === 'available' ? 'request' : 'integration'

    if (openedSlider !== sliderType) {
      onSliderOpen(sliderType)
      setSelectedModule(moduleCard.value)
    }
  }

  const onSliderOpen = (sliderType: ProjectSliderType) => {
    setOpenedSlider(sliderType)
  }

  const onSliderClose = useCallback((dialogType: ProjectDialogTrigger, dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) setOpenedSlider(null)
    else {
      setConfirmDialogTrigger(dialogType)
      openConfirm()
    }
  }, [])

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) setOpenedSlider(null)
  }, [])

  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<ProjectDialogTrigger>('exit')

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    exit: { handleConfirm: handleExitConfirm },
  }

  const { ConfirmDialog, openConfirm, isConfirmDialogOpened } = useConfirmDialog(
    dataForConfirmDialog[confirmDialogTrigger],
  )

  const onDrawerClose = useCallback(() => {
    if (isConfirmDialogOpened) return

    onClose()
    setOpenedSlider(null)
  }, [isConfirmDialogOpened])

  return (
    <Drawer onClick={(e) => e.stopPropagation()} anchor='right' open={isOpen} onClose={onDrawerClose}>
      <ProjectIntegrationWrapper width={'400px'} height={'100%'}>
        <Stack height={'100%'}>
          <DrawerTopBar>
            <Typography variant='h1' color={theme.palette.primary.main}>
              Интеграции по API
            </Typography>
          </DrawerTopBar>

          <Divider />

          <Stack direction={'row'} justifyContent={'space-between'} padding={'20px'}>
            {intergationModuleCards.map((module) => (
              <IntegrationModuleCard onClick={() => onModuleClick(module)}>
                <IntegrationModuleStatusWrapper bgColor={integrationModuleStatusColor[module.status]}>
                  <Typography variant='body2' fontSize={12} color={theme.palette.text.light} lineHeight={'inherit'}>
                    {integrationModuleStatusRuByEn[module.status].toUpperCase()}
                  </Typography>
                </IntegrationModuleStatusWrapper>
                {module.icon}
                <Typography variant='body2' fontWeight={'700'} color={theme.palette.text.dark}>
                  {module.label}
                </Typography>
              </IntegrationModuleCard>
            ))}
          </Stack>
        </Stack>
        <FormButtonWrapper padding={2.5} spacing={1}>
          <Button
            size='medium'
            style={{
              maxWidth: '100%',
            }}
            fullWidth
            onClick={onClose}
          >
            Закрыть
          </Button>
        </FormButtonWrapper>

        <IntegrationProjectSlide
          isOpen={openedSlider === 'integration'}
          onClose={(dirty, immediately) => onSliderClose('exit', dirty, immediately)}
          isConfirmDialogOpened={isConfirmDialogOpened}
          project={project}
          module={selectedModule}
        />

        <IntegrationRequestSlide
          isOpen={openedSlider === 'request'}
          onClose={(dirty, immediately) => onSliderClose('exit', dirty, immediately)}
          isConfirmDialogOpened={isConfirmDialogOpened}
          projectId={projectId}
          module={selectedModule}
        />
      </ProjectIntegrationWrapper>

      <ConfirmDialog />
    </Drawer>
  )
}

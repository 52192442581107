import { Wrapper as PartiesWrapper } from 'pages/Remarks/components/RemarkForm/Parties/Parties.styles'
import styled from 'styled-components'

interface IFileLabelProps {
  isDisabled?: boolean
}

export const Wrapper = styled(PartiesWrapper)`
  width: 35.75%;

  @media (max-width: 1555px) {
    width: 49%;
    margin-right: 0;
  }

  @media (max-width: 1052px) {
    width: 99.5%;
    margin-top: 20px;
  }
`

export const HiddenInput = styled.input`
  display: none;
`

export const FileLabel = styled.label<IFileLabelProps>`
  ${(props) =>
    props.isDisabled
      ? `
        pointer-events: none;
      `
      : `
        &:hover {
          cursor: pointer;
        }
      `}
`

import { ButtonData, UserButtonsProps } from './UserButtons.types'
import EditIcon from '@mui/icons-material/Edit'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, Stack } from '@mui/material'
import { AnalyticsIcon } from 'assets/icons/AnalyticsIcon'
import { Button } from 'components/Button'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const UserButtons: React.FC<UserButtonsProps> = ({ role }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isAdminPage: boolean = location.pathname.split('/')[1] === 'administration'

  const adminButtonData: ButtonData = {
    text: 'Администрирование',
    icon: <EditIcon />,
    href: '/administration/users',
  }
  const viewButtonData: ButtonData = {
    text: 'Режим просмотра',
    icon: <VisibilityIcon />,
    href: '/projects',
  }

  const buttonData = isAdminPage ? viewButtonData : adminButtonData

  const onButtonClick = (href: string) => {
    navigate(href)
  }

  const onRegulationsClick = () => {
    navigate('/regulations')
  }

  const onAnaliticsClick = () => {
    navigate('/analytics')
  }

  return (
    <Box>
      <Stack direction='row' spacing={1.5}>
        <Button onClick={onAnaliticsClick} icon>
          <AnalyticsIcon />
        </Button>
        <Button onClick={onRegulationsClick} icon>
          <MenuBookIcon />
        </Button>
        {role === 'admin' ? (
          <Button customSize='medium' startIcon={buttonData.icon} onClick={() => onButtonClick(buttonData.href)}>
            {buttonData.text}
          </Button>
        ) : null}
      </Stack>
    </Box>
  )
}

export const defaultRemarkFilters = {
  status: null,
  contractor: null,
  contractorStatus: null,
  number: null,
  period_dateStart: null,
  period_dateStart_range: null,
  period_dateEnd: null,
  period_dateEnd_range: null,
  period_dateDone: null,
  period_dateDone_range: null,
  prescription: null,
  type: null,
}

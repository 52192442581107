import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Stack, Typography } from '@mui/material'
import styled, { css } from 'styled-components'

interface FullWrapperProps {
  maxWidth: number | undefined
}

export const FullWrapper = styled(Stack)<FullWrapperProps>`
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  width: 100%;
  position: relative;
  height: fit-content;
`

interface SelectWrapperProps {
  isDisabled: boolean | undefined
}

export const SelectWrapper = styled.div<SelectWrapperProps>`
  display: flex;
  position: relative;

  ${(props) =>
    !props.isDisabled &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`

interface SelectProps {
  error: boolean
  readOnly?: boolean
  isSubtext?: boolean
  isDisabled?: boolean
}

const Initial = css<SelectProps>`
  padding: 5px 32px ${(props) => (props.isSubtext ? '22px' : '5px')} 8px;
  width: 100%;
  text-align: end;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border: ${(props) => (!props.error ? '2px solid rgba(120, 144, 178, 0.1)' : '2px solid transparent')};
  outline: ${(props) => (props.error ? `1px solid ${props.theme.palette.error.main}` : 'none')};
  pointer-events: ${(props) => props.readOnly && 'none'};

  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.palette.text.dark};

  ${(props) =>
    !props.readOnly &&
    !props.isDisabled &&
    `
    &:focus {
      pointer-events: none;
      border: 2px solid ${props.theme.palette.primary.main};
      outline: none;
      & ~ .MuiSvgIcon-root {
        transform: rotate(180deg);
        fill: ${props.theme.palette.primary.main};
      }
    }
  `}

  ${(props) =>
    !props.isDisabled
      ? `
    &:hover {
      cursor: pointer;
      outline: ${!props.error && '1px solid #000000'};
    }
  `
      : `
    color: ${props.theme.palette.legends.disabled};
    pointer-events: none;

    & ~ .MuiSvgIcon-root {
      fill: ${props.theme.palette.legends.disabled};
    }
  `}

  &::placeholder {
    color: ${(props) => props.theme.palette.legends.disabled};
  }
`

export const Select = styled.input<SelectProps>`
  ${Initial}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TextArea = styled.textarea<SelectProps>`
  ${Initial}
  white-space: normal;
  font-family: sans-serif;
  line-height: 20px;

  resize: none;
  overflow: hidden;
  min-height: 52px;
  max-height: 100%;
`

export const ArrowIcon = styled(ArrowDropDownIcon)`
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  margin: auto;
  fill: ${(props) => props.theme.palette.text.dark};
  transition: transform 0.3s;
  pointer-events: none;
`

export const Dropdown = styled(Stack)`
  max-height: 250px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.palette.bg.white};
  position: absolute;
  z-index: 5;
  padding: 8px 0;
  top: calc(100% + 4px);
  border-radius: 4px;
  width: 100%;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`

export const List = styled(Stack)`
  border-right: 1px solid ${(props) => props.theme.palette.legends.gray};
`

interface ListItemProps {
  selected?: boolean
  availableToAdd?: boolean
}

export const ListItem = styled(Stack)<ListItemProps>`
  padding: 10px 16px;
  background-color: ${(props) => props.selected && 'rgba(0, 68, 180, 0.08)'};

  ${(props) =>
    props.availableToAdd &&
    `
    &:hover {
      background-color: ${props.theme.palette.bg.shades};
      cursor: pointer;
      color: ${props.theme.palette.primary.main};
    }
  `}
`

export const GroupTitle = styled(Typography)`
  padding: 10px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.palette.primary.main};
  text-align: start;

  position: sticky;
  top: -8px;
  z-index: 5;
  background-color: #edf4fb;
  border-bottom: 1px solid ${(props) => props.theme.palette.legends.gray};
  font-weight: 500;
  line-height: 28px;
`

export const Subtext = styled(Typography)`
  pointer-events: none;
  position: absolute;
  top: calc(100% - 23px);
  left: 10px;

  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

import { PrescriptionFormData } from '../../PrescriptionsForm.types'
import { Wrapper } from './Info.styles'
import { InfoProps, inspectionTypeDropdown, typeDropdown } from './Info.types'
import {
  ErrorOutline as ErrorOutlineIcon,
  EventAvailable as EventAvailableIcon,
  StickyNote2Outlined as StickyNoteIcon,
  ReceiptLongOutlined as TypeIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import { IconButton, Menu, Stack, Typography } from '@mui/material'
import { ColoredTitle } from 'components/ColoredTitle'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection'
import {
  PrescriptionStatus,
  prescriptionStatusEnByRu,
  prescriptionStatusRuByEn,
  prescriptionStatuses,
  prescriptionStatusesColor,
} from 'core/types/prescription'
import { useFormikContext } from 'formik'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { StatusCellContent } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { FC, useState, useCallback, MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useTypedSelector } from 'store'
import { profileSelector } from 'store/slices/profile'
import { theme } from 'styles/theme'

export const Info: FC<InfoProps> = ({ viewingOnly, formMode, isStatusChangeAvailable }) => {
  const profile = useTypedSelector(profileSelector)
  const { values, setFieldValue } = useFormikContext<PrescriptionFormData>()
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const numberEditingAccess: boolean = profile.role === 'admin'

  const onStatusCellClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setMenuAnchor(e.currentTarget)
  }, [])

  const ColoredStatus = (
    <ColoredTitle body={prescriptionStatusRuByEn[values.status]} color={prescriptionStatusesColor[values.status]} />
  )

  const onMenuClose = useCallback(() => {
    setMenuAnchor(null)
  }, [])

  const onMenuClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const onMenuItemClick = useCallback(
    (status: PrescriptionStatus) => {
      setMenuAnchor(null)
      setFieldValue('status', status)
    },
    [projectId],
  )

  return (
    <Wrapper>
      <Typography marginBottom={1.5} variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
        Общая информация
      </Typography>
      <Stack spacing={1.5} marginBottom={5.5}>
        {/* @ts-ignore */}
        <FieldItem title='Статус' marginBottom={1}>
          <StatusCellContent onClick={onStatusCellClick} display={'flex'} alignItems={'center'}>
            {ColoredStatus}
            {isStatusChangeAvailable && (
              <IconButton>
                <KeyboardArrowDownIcon fontSize='small' />
              </IconButton>
            )}
          </StatusCellContent>
          {isStatusChangeAvailable && (
            <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClick={onMenuClick} onClose={onMenuClose}>
              {prescriptionStatuses.map((menuStatus) => {
                const valueForOnClick = prescriptionStatusEnByRu[menuStatus]
                const checked = values.status === valueForOnClick

                return (
                  <StyledSelectMenuItem
                    onClick={() => onMenuItemClick(valueForOnClick)}
                    style={{ width: '100%', maxWidth: '100%' }}
                    value={menuStatus}
                    checked={checked}
                    key={menuStatus}
                  >
                    {menuStatus}
                  </StyledSelectMenuItem>
                )
              })}
            </Menu>
          )}
        </FieldItem>
        <FieldItem title='Номер' icon={<ErrorOutlineIcon fontSize={'medium'} color={'secondary'} />} isRequired={true}>
          <FieldForm
            version='project'
            placeholder='123'
            name='number'
            dataValue={values.number}
            fullWidth={false}
            disabled={!numberEditingAccess}
            inputProps={{
              readOnly: !numberEditingAccess,
              style: { padding: '5px 8px', width: '184px', textAlign: 'end' },
            }}
          />
        </FieldItem>
        <FieldItem
          title='Дата предписания'
          icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
          isRequired={true}
        >
          <FieldForm
            version='date'
            name='dateStart'
            placeholder='дд.мм.гггг'
            helperText=''
            dataValue={values.dateStart}
            onDataChange={(value: Date | null) => setFieldValue('dateStart', value)}
            style={{ maxWidth: '144px', width: '100%' }}
            dateFieldProps={{
              maxDate: new Date() || undefined,
              readOnly: viewingOnly,
            }}
          />
        </FieldItem>
        <FieldItem
          title='Вид обследования'
          icon={<StickyNoteIcon fontSize={'medium'} color={'secondary'} />}
          isRequired={true}
        >
          <CustomSelect
            name='inspectionType'
            list={inspectionTypeDropdown || []}
            placeholder={!viewingOnly ? 'Выберите вид' : ''}
            isSearch={true}
            width={200}
            readOnly={viewingOnly}
          />
        </FieldItem>
        <FieldItem
          title='Тип предписания'
          icon={<TypeIcon fontSize={'medium'} color={'secondary'} />}
          isRequired={true}
        >
          <CustomSelect
            name='type'
            list={typeDropdown || []}
            placeholder='Выберите тип'
            isSearch={true}
            width={200}
            readOnly={viewingOnly}
          />
        </FieldItem>
      </Stack>
      <Stack>
        <Typography variant='subtitle1' textAlign={'start'} marginBottom={'16px'} color={theme.palette.text.dark}>
          Комментарии
        </Typography>
        <FieldForm
          version='project'
          name='comment'
          placeholder={!viewingOnly ? 'Комментарий' : ''}
          multiline
          inputProps={{
            readOnly: viewingOnly,
            style: {
              minHeight: '60px',
            },
          }}
        />
      </Stack>
    </Wrapper>
  )
}

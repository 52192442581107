import { RegulationDoc } from '../../pages/RegulationDoc'
import { AdminLayout } from 'layouts/AdminLayout/AdminLayout'
import { Layout } from 'layouts/Layout/Layout'
import { MainLayout } from 'layouts/MainLayout'
import { AdminProjects } from 'pages/Administration/AdminProjects'
import { AddUser } from 'pages/Administration/AdminUsers/AddUser'
import { AdminUsers } from 'pages/Administration/AdminUsers/AdminUsers'
import { EditUser } from 'pages/Administration/AdminUsers/EditUser'
import { ProjectManagement } from 'pages/Administration/ProjectManagement'
import { References } from 'pages/Administration/References/References'
import { Analytics } from 'pages/Analytics'
import { Documents } from 'pages/Documents'
import { Ksg } from 'pages/Ksg'
import { Objects } from 'pages/Objects'
import { Prescriptions } from 'pages/Prescriptions'
import { PrescriptionsForm } from 'pages/Prescriptions/components/PrescriptionsForm'
import { ProjectDashboard } from 'pages/ProjectDashboard'
import { ProjectReferences } from 'pages/ProjectReferences'
import { Projects } from 'pages/Projects'
import { Regulations } from 'pages/Regulations'
import { Remarks } from 'pages/Remarks'
import { RemarkForm } from 'pages/Remarks/components/RemarkForm'
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path='' element={<Layout />}>
        <Route path='' element={<MainLayout />}>
          <Route path='regulations' element={<Regulations />} />
          <Route path='analytics' element={<Analytics />} />

          <Route path='projects' element={<Projects />} />
          <Route path='project/:projectId' element={<ProjectDashboard />} />

          <Route path='project/:projectId/remarks' element={<Remarks />} />
          <Route path='project/:projectId/prescriptions' element={<Prescriptions />} />
          <Route path='project/:projectId/ksg' element={<Ksg />} />

          <Route path='objects/:projectId' element={<Objects />} />

          <Route path='project/:projectId/references' element={<ProjectReferences />} />
          <Route path='project/:projectId/references/:referenceId' element={<ProjectReferences />} />

          <Route path='*' element={<Navigate to='/projects' />} />
        </Route>

        <Route path='project/:projectId/documents' element={<Documents />} />
        <Route path='project/:projectId/documents/:docId' element={<Documents />} />

        <Route path='regulations/:sectionId/doc/:docId' element={<RegulationDoc />} />

        <Route path='project/:projectId/remarks/add' element={<RemarkForm />} />
        <Route path='project/:projectId/remarks/edit/:remarkId' element={<RemarkForm />} />

        <Route path='project/:projectId/prescriptions/add' element={<PrescriptionsForm />} />
        <Route path='project/:projectId/prescriptions/edit/:prescriptionId' element={<PrescriptionsForm />} />

        <Route path='administration' element={<AdminLayout />}>
          <Route path='users' element={<AdminUsers />} />
          <Route path='users/add' element={<AddUser />} />
          <Route path='users/edit/:userId' element={<EditUser />} />

          <Route path='projects' element={<AdminProjects />} />
          <Route path='projects/add' element={<ProjectManagement />} />
          <Route path='projects/edit/:projectId' element={<ProjectManagement />} />

          <Route path='references' element={<References />} />
          <Route path='references/:referenceId' element={<References />} />

          <Route path='*' element={<Navigate to='/administration/users' />} />
        </Route>

        <Route index element={<Navigate to='/projects' />} />
      </Route>
    </Routes>
  )
}

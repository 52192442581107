import { DateDoneCell } from './cells/DateDoneCell'
import { EliminationCell } from './cells/EliminationCell'
import { StatusCell, StatusCellMenuItemProps } from './cells/StatusCell'
import { ColumnDef } from '@tanstack/react-table'
import { RowData } from '@tanstack/table-core/src/types'
import { Prescription } from 'api/prescriptions/types'
import {
  RemarkAuthor,
  RemarkFull,
  RemarkFullContractor,
  RemarkFullDescription,
  RemarkFullResponsible,
  RemarkStatuses,
  RemarksFilters,
  remarkStatuses,
  remarkStatusesBackend,
  remarkStatusesClient,
  remarkStatusesColor,
} from 'api/remarks/types'
import { SortingOrder } from 'core/types/prescription'
import { SortableColumn } from 'core/types/remarks'
import { isNumber } from 'lodash'
import { CSSProperties } from 'react'
import { connectNames } from 'utils/connectNames'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export interface RemarksTableProps {
  projectId: number
  sort: SortData
  filter: FilterData
}

export type CustomColumnDef<TData extends RowData> = ColumnDef<TData> &
  Pick<CSSProperties, 'textAlign'> & {
    isCustomCell?: boolean
  }

export interface CurrentSortableColumn {
  column: SortableColumn
  order: SortingOrder
}

export interface SortData {
  sortableColumn: CurrentSortableColumn | null
  setSortableColumn: (column: CurrentSortableColumn | null) => void
}

export interface FilterData {
  filterData: RemarksFilters
  setFilterData: (filters: RemarksFilters | ((prev: RemarksFilters) => RemarksFilters)) => void
}

export type RemarkTableDialogTrigger = 'notification'

export interface NotificationData {
  status: RemarkStatuses
  remarkId: number
  primary: number | null
  dateDone: string | null
}

// Columns
export const numberColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'number',
  header: '№',
  minSize: 120,
  size: 120,
  maxSize: 120,
}

export const getStatusColumn = (
  onStatusSelect: (
    projectId: number,
    prescriptionId: number,
    primary: number | null,
    status: RemarkStatuses,
    dateDone: string,
  ) => void,
): CustomColumnDef<RemarkFull> => ({
  accessorKey: 'status',
  header: 'Статус',
  minSize: 180,
  size: 180,
  maxSize: 180,
  cell: (info) => {
    const status = info.getValue<RemarkStatuses>()
    const { prescription, period, copyNumber, primary } = info.row.original

    const isIncludedCopy = Boolean(copyNumber && prescription)
    const isNotIncludedCopy = Boolean(copyNumber && !prescription)

    const editingAvailable = isIncludedCopy || !isNotIncludedCopy

    const prescriptionNumber = prescription?.number

    const statusCellMenuItemsData: StatusCellMenuItemProps<RemarkStatuses>[] = remarkStatuses
      .filter((menuStatus) => menuStatus !== 'Исполнено' || (menuStatus === 'Исполнено' && prescriptionNumber))
      .map((menuStatus) => {
        const valueForOnClick = remarkStatusesBackend[menuStatus]
        const checked = status === valueForOnClick

        return {
          value: menuStatus,
          valueForOnClick,
          checked: checked,
          key: menuStatus,
          children: menuStatus,
        }
      })

    return (
      <StatusCell
        variant='remarks'
        info={info}
        onSelect={(projectId, remarkId, status) =>
          onStatusSelect(projectId, remarkId, primary, status, period?.dateDone)
        }
        itemsData={statusCellMenuItemsData}
        statusData={{
          body: remarkStatusesClient[status],
          color: remarkStatusesColor[status],
        }}
        editingAvailable={editingAvailable}
      />
    )
  },
  isCustomCell: true,
})

export const objectColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'object.title',
  header: 'Объект строительства',
  minSize: 205,
  size: 205,
  maxSize: 220,
  textAlign: 'left',
}

export const descriptionColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'description',
  header: 'Описание',
  minSize: 240,
  size: 240,
  textAlign: 'left',
  cell: (info) => {
    const { custom, defect } = info.getValue<RemarkFullDescription>()
    return custom || defect?.title || '—'
  },
}

export const dateStartColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'period.dateStart',
  header: 'Дата замечания',
  minSize: 180,
  size: 180,
  maxSize: 180,
  cell: (info) => {
    const value = info.getValue<string>()
    return parseResponseDate(value).fullDate
  },
}

export const authorColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'author',
  header: 'Автор',
  minSize: 260,
  size: 260,
  maxSize: 260,
  textAlign: 'left',
  cell: (info) => {
    const { firstName, lastName, middleName } = info.getValue<RemarkAuthor>()
    return `${lastName || ''} ${firstName || ''} ${middleName || ''}`
  },
}

export const dateEndColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'period.dateEnd',
  header: 'Уст. дата устранения',
  minSize: 180,
  size: 180,
  maxSize: 180,
  cell: (info) => <DateDoneCell info={info} dateVariant='end' splitDeviation />,
  isCustomCell: true,
}

export const dateDoneColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'period.dateDone',
  header: 'Факт. дата устранения',
  minSize: 180,
  size: 180,
  maxSize: 180,
  cell: (info) => <DateDoneCell info={info} dateVariant='done' splitDeviation />,
  isCustomCell: true,
}

export const contractorStatusColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'contractorStatus',
  header: 'Статус устранения',
  minSize: 160,
  size: 160,
  maxSize: 160,
  cell: (info) => <EliminationCell info={info} />,
  isCustomCell: true,
}

export const contractorColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'contractor',
  header: 'Подрядчик',
  minSize: 260,
  size: 260,
  maxSize: 260,
  cell: (info) => {
    const { contractor, company } = info.getValue<RemarkFullContractor>()
    return contractor?.shortName || company || '—'
  },
  textAlign: 'left',
}

export const prescriptionNumberColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'prescription',
  header: 'Номер предписания',
  minSize: 200,
  size: 200,
  maxSize: 220,
  cell: (info) => info.getValue<Prescription>()?.number || '—',
  textAlign: 'left',
}

export const assignmentTypeColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'assignmentType.title',
  header: 'Вид работ',
  minSize: 205,
  size: 205,
  maxSize: 220,
  textAlign: 'left',
}

export const typeColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'type',
  header: 'Тип замечания',
  minSize: 140,
  size: 160,
  maxSize: 160,
  textAlign: 'center',
  cell: (info) => {
    const copyNumber = info.row.original.copyNumber
    return isNumber(copyNumber) ? 'Повторное' : 'Первичное'
  },
}

export const measuresColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'measures.custom',
  header: 'Мероприятия по устранению',
  minSize: 240,
  size: 240,
  textAlign: 'left',
}

export const basisColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'basis',
  header: 'Основание',
  minSize: 240,
  size: 240,
  textAlign: 'left',
}

export const commentColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'comment',
  header: 'Комментарии',
  minSize: 240,
  size: 240,
  textAlign: 'left',
}

export const responsibleColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'responsible',
  header: 'Ответственный',
  minSize: 240,
  size: 240,
  textAlign: 'left',
  cell: (info) => {
    const { representative, user } = info.getValue<RemarkFullResponsible>()
    return representative?.fullName || (user && connectNames(user)) || '—'
  },
}

export const prescriptionDateStartColumn: CustomColumnDef<RemarkFull> = {
  accessorKey: 'prescription.period.dateStart',
  header: 'Дата предписания',
  minSize: 200,
  size: 200,
  maxSize: 220,
  cell: (info) => {
    const value = info.getValue<string>() || '—'
    return parseResponseDate(value).fullDate
  },
  textAlign: 'left',
}

import { Config, ConfigItem } from './config.types'

const configData: ConfigItem[] = [
  // ---------------dev-----------------------------
  {
    companyName: 'PragmaCore',
    appHost: 'localhost',
    apiHost: 'https://dev.api.qc.pragma.info',

    coreURL: 'https://dev.core.pragma.info/',
  },
  // ---------------demo-----------------------------
  {
    companyName: 'PragmaCore',
    appHost: 'dev.qc.pragma.info',
    apiHost: 'https://dev.api.qc.pragma.info',

    coreURL: 'https://dev.core.pragma.info/',
  },
  {
    companyName: 'Incepta Group',
    appHost: 'ig.qc.pragma.info',
    apiHost: 'https://ig.api.qc.pragma.info',
  },
  // ---------------prod------------------------------
  {
    companyName: 'PragmaCore',
    appHost: 'qc.pragma.info',
    apiHost: 'https://api.qc.pragma.info',
  },
  {
    companyName: 'ООО «Смол-ДорНИИ-Проект»',
    appHost: 'smoldor.qc.pragma.info',
    apiHost: 'https://smoldor.api.qc.pragma.info',
  },
  {
    companyName: 'ФГКУ Росгранстрой',
    appHost: 'rgs.qc.pragma.info',
    apiHost: 'https://rgs.api.qc.pragma.info',
  },
  {
    companyName: 'Capital Group',
    appHost: 'cg.qc.pragma.info',
    apiHost: 'https://cg.api.qc.pragma.info',
  },
  {
    companyName: 'АО «СК «Мост»',
    appHost: 'most.qc.pragma.info',
    apiHost: 'https://most.api.qc.pragma.info',
  },
  {
    companyName: 'ГЕО',
    appHost: 'geo.qc.pragma.info',
    apiHost: 'https://geo.api.qc.pragma.info',
  },
  {
    companyName: 'ООО «СУ-555»',
    appHost: 'su555.qc.pragma.info',
    apiHost: 'https://su555.api.qc.pragma.info',
  },
  {
    companyName: 'ООО ГК «СУ-555»',
    appHost: 'gcsu-555.qc.pragma.info',
    apiHost: 'https://gcsu-555.api.qc.pragma.info',
  },
  {
    companyName: 'ООО «Рустэко»',
    appHost: 'rusteko.qc.pragma.info',
    apiHost: 'https://rusteko.api.qc.pragma.info',
  },
  {
    companyName: 'РЖД',
    appHost: 'rzd.qc.pragma.info',
    apiHost: 'https://rzd.api.qc.pragma.info',
  },
  {
    companyName: 'ООО ПО «Квант»',
    appHost: 'kvant.qc.pragma.info',
    apiHost: 'https://kvant.api.qc.pragma.info',
  },
  {
    companyName: 'АО «КРНО»',
    appHost: 'krno.qc.pragma.info',
    apiHost: 'https://krno.api.qc.pragma.info',
  },
  {
    companyName: 'АО «ГК «ЕКС»',
    appHost: 'eks.qc.pragma.info',
    apiHost: 'https://eks.api.qc.pragma.info',
  },
  {
    companyName: 'ФБУ «РосСтройКонтроль»',
    appHost: 'rsk.qc.pragma.info',
    apiHost: 'https://rsk.api.qc.pragma.info',
  },
  {
    companyName: 'ООО «ЭСК «Энергомост»',
    appHost: 'energomost.qc.pragma.info',
    apiHost: 'https://energomost.api.qc.pragma.info',
  },
  {
    companyName: 'Черное море',
    appHost: 'blacksea.qc.pragma.info',
    apiHost: 'https://blacksea.api.qc.pragma.info',
  },
  {
    companyName: 'Группа «Самолет»',
    appHost: 'samolet.qc.pragma.info',
    apiHost: 'https://samolet.api.qc.pragma.info',
  },
]

const currentConfig = configData.find((it) => {
  return document.location.hostname === it.appHost
})

export const config: Config = {
  coreURL: currentConfig?.coreURL ?? 'https://core.pragma.info/',
  appHost: currentConfig?.appHost ?? 'localhost',
  apiHost: currentConfig?.apiHost ?? 'https://api.qc.pragma.info',
  companyName: currentConfig?.companyName ?? 'PragmaCore',
}

import { SvgIconComponent } from '@mui/icons-material'
import { ObjectsIcon } from 'assets/icons/ObjectsIcon'
import { ReactNode } from 'react'
import { theme } from 'styles/theme'

export interface WithChildren<T = ReactNode> {
  children: T
}

// Types
export interface Media {
  id: number
  key: string
  link: string
  comment: string
  variations: MediaVariations
  created: string
}

export interface MediaVariations {
  big: string
  medium: string
  small: string
}

export interface FullPeriod {
  dateDone: string
  dateEnd: string
  dateStart: string
  deviation: number
  isExpired: boolean
  automaticDateEnd: boolean
}

// Excel
export type ExcelParseErrorType =
  | 'unknown'
  | 'sheet_not_found'
  | 'many_sheets'
  | 'missed_columns'
  | 'cell_not_found'
  | 'empty_cell'
  | 'invalid_cell_value'
  | 'unacceptable_cell_value'

type BlockErrors = Extract<ExcelParseErrorType, 'unknown' | 'many_sheets' | 'sheet_not_found' | 'missed_columns'>

export interface ExcelParseError {
  col: number
  row: number
  message: string
  type: ExcelParseErrorType
}

export const errorTextByType: Record<ExcelParseErrorType, string> = {
  unknown: 'Неизвестная ошибка',
  sheet_not_found: 'Книга не содержит данных',
  many_sheets: 'Книга содержит несколько листов',
  missed_columns: 'Отсутствуют обязательные данные',
  cell_not_found: 'Не найдена колонка',
  empty_cell: 'Не найдено значение',
  invalid_cell_value: 'Неприемлемые данные',
  unacceptable_cell_value: 'Неуникальные данные',
}

export const isBlockError = (error: any): error is BlockErrors => {
  return ['unknown', 'many_sheets', 'sheet_not_found', 'missed_columns'].includes(error)
}

export interface FileUploadResponse<T> extends StatusResponse {
  data: T
}

export interface ExcelUploadResponse {
  create: number
  error: ExcelParseError[]
  success: boolean
  update: number
}

export interface UploadStatus {
  description: string
  fileName: string
}

export interface DefaultMultiResponse {
  failed: UploadStatus[]
  success: UploadStatus[]
}

// API props
export interface StatusResponse {
  description: string
  success: boolean
}

export interface Limit {
  value: number
  title: string
}

export interface PaginationProps {
  limit?: number
  offset?: number
  query?: string
}

export interface OtherPaginationProps {
  num?: number
  page?: number
  query?: string
}

export interface QueryObserverProps {
  num?: number
  last?: number | null
  reverse?: boolean
  centered?: boolean
}

// Components
export type IconComponent = SvgIconComponent | typeof ObjectsIcon

export type LegendColors = keyof typeof theme.palette.legends
export const isLegendColor = (colorName: string): colorName is LegendColors => {
  return colorName in theme.palette.legends
}

export interface ComponentPaginationProps {
  limit: number
  onChangeLimit: (limit: number) => void
  numberRows: Limit[]
  page: number
  onChangePage: (page: number) => void
  countPagination: number
}

// UI
export type DirtyFormForGlobalConfirm = 'project' | 'user' | ''

export interface FileWithId extends File {
  temporaryId: number
}

export interface AuditMails {
  email: string
  fullName: string
}

export type MediaType = 'SHORTCOMING' | 'SHORTCOMING_CONTRACTOR' | 'PRESCRIPTION' | 'REGULATORY_DOC' | 'COMPLETION_ACT'

export interface IDropdownData {
  id: number
  text: string
  subtext: string
}

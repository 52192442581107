import { RemarkSecondaryFormWrapper } from './RemarkSecondaryForm.styles'
import { RemarkSecondaryFormData, RemarkSecondaryFormProps } from './RemarkSecondaryForm.types'
import { remarkSecondaryValidation } from './validation'
import {
  EventAvailable as EventAvailableIcon,
  ErrorOutline as ErrorOutlineIcon,
  Person as PersonIcon,
} from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { remarksApi, useCreateRemarkSecondaryMutation } from 'api/remarks'
import { RemarkSecondaryShort } from 'api/remarks/types'
import { CustomSelect } from 'components/CustomSelect'
import { FieldForm } from 'components/FieldForm'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FormButtonWrapper } from 'pages/Regulations/RegulationsDrawers/DocumentManagment/DocumentManagmentForm/DocumentManagmentForm.styles'
import { FieldItem } from 'pages/Remarks/components/RemarkForm/components/FieldItem'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from 'store/index'
import { theme } from 'styles/theme'
import { formatDateForServer } from 'utils/dates/formatDateForServer'
import { removeTimeFromDate } from 'utils/dates/removeTimeFromDate'

export const RemarkSecondaryForm: FC<RemarkSecondaryFormProps> = (props: RemarkSecondaryFormProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { projectId: projectIdString, remarkId: remarkIdString } = useParams()
  const [createRemarkSecondary, { isLoading: isCreating, ...createRemarkSecondaryResponse }] =
    useCreateRemarkSecondaryMutation()

  const projectId = Number(projectIdString)
  const remarkId = Number(remarkIdString)

  /****************************************** Formik *************************************************/
  const initialValues: RemarkSecondaryFormData = useMemo(
    () => ({
      number: processWord(props.initialValues.remarkNumber),
      dateStart: new Date(),
      dateEnd: null,
      responsible: props.initialValues.remarkResponsible,
      comment: null,
      initialValues: props.initialValues,
      dateNow: removeTimeFromDate(new Date()),
    }),
    [props.initialValues],
  )

  const { formik } = useForm({
    validationSchema: remarkSecondaryValidation,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, dirty, isValid } = formik

  /****************************************** Functions *************************************************/
  const onSubmit = useCallback((values: RemarkSecondaryFormData) => {
    const dataForRequest: RemarkSecondaryShort = {
      period: {
        dateStart: formatDateForServer(values.dateStart),
        dateEnd: formatDateForServer(values.dateEnd),
        dateDone: null,
      },
      responsible: values.responsible?.value
        ? {
            representative: values.responsible?.type === 'representative' ? Number(values.responsible?.id) : null,
            user: values.responsible?.type === 'user' ? Number(values.responsible?.id) : null,
            custom: values.responsible?.type === 'custom' ? values.responsible?.value : null,
          }
        : null,
      comment: values.comment,
    }

    createRemarkSecondary({
      projectId: projectId,
      remarkId: props.initialValues.primary || remarkId,
      body: dataForRequest,
    })
  }, [])

  function processWord(word: string) {
    const matchResult = word.match(/\((\d+)\)/)

    if (matchResult) {
      const digit = parseInt(matchResult[1])
      const newDigit = digit + 1
      const newWord = word.replace(/\(\d+\)/, `(${newDigit})`)
      return newWord
    } else {
      return `${word}(1)`
    }
  }
  /****************************************** useMutationHandlers *************************************************/
  useMutationHandlers(createRemarkSecondaryResponse, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }
    dispatch(remarksApi.util.invalidateTags(['Remarks']))
    enqueueSnackbar('Повторное замечание успешно создано.', { variant: 'success' })
    navigate(`/project/${projectId}/remarks`)
  })

  /****************************************** useEffect *************************************************/
  useEffect(() => {
    props.onFormChange(dirty)
  }, [dirty])

  return (
    <RemarkSecondaryFormWrapper>
      <FormikProvider value={formik}>
        <Stack component={Form} height={'100%'} justifyContent={'space-between'}>
          <Stack spacing={2.5} height={'100%'} overflow={'auto'} padding={'20px'}>
            <Stack padding={'5.5px'} justifyContent={'center'} alignItems={'center'}>
              <Typography variant='body2' color={theme.palette.text.dark} textAlign={'center'}>
                Для создания повторного замечания укажите Дату замечания (осмотра, во время которого подтверждено
                нарушение) и Установленную дату устранения. Информация о нарушении, устранении подрядчиком в повторном
                замечании будет автоматически синхронизироваться с первичным.
              </Typography>
            </Stack>
            <Stack spacing={1.5}>
              <FieldItem
                title='Дата повторного замечания'
                icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
                isRequired={true}
              >
                <FieldForm
                  version='date'
                  name='dateStart'
                  placeholder='дд.мм.гггг'
                  helperText=''
                  style={{ maxWidth: '144px', width: '100%' }}
                  dataValue={values.dateStart}
                  onDataChange={(value: Date | null) => setFieldValue('dateStart', value)}
                  dateFieldProps={{
                    minDate: values.initialValues.remarkDateStart || undefined,
                    maxDate: values.dateNow || undefined,
                  }}
                />
              </FieldItem>
              <FieldItem
                title='Номер'
                isRequired={true}
                icon={<ErrorOutlineIcon fontSize={'medium'} color={'secondary'} />}
              >
                <FieldForm
                  version='project'
                  name='number'
                  fullWidth={false}
                  disabled={true}
                  inputProps={{
                    readOnly: true,
                    style: {
                      padding: '5px 8px',
                      width: '126px',
                      textAlign: 'end',
                    },
                  }}
                />
              </FieldItem>
              <FieldItem
                title='Установленная дата устранения'
                icon={<EventAvailableIcon fontSize={'medium'} color={'secondary'} />}
                isRequired={true}
              >
                <FieldForm
                  version='date'
                  name='dateEnd'
                  placeholder='дд.мм.гггг'
                  helperText=''
                  style={{ maxWidth: '144px', width: '100%' }}
                  dataValue={values.dateEnd}
                  onDataChange={(value: Date | null) => setFieldValue('dateEnd', value)}
                  dateFieldProps={{
                    minDate: values.dateNow || undefined,
                  }}
                />
              </FieldItem>
              <CustomSelect
                name='responsible'
                list={props.localResponsibleList}
                isSearch={true}
                free={true}
                isSubtext={true}
                placeholder={'Выберите из списка'}
                label='Ответственный'
                style={{
                  textAlign: 'start',
                }}
                icon={<PersonIcon fontSize={'medium'} color={'secondary'} />}
              />
            </Stack>
            <Stack>
              <FieldForm
                version='project'
                name='comment'
                multiline
                required={false}
                placeholder={'Комментарий'}
                dataValue={values.comment}
                onDataChange={(value: Date | null) => setFieldValue('comment', value)}
                inputProps={{
                  style: {
                    minHeight: '130px',
                    padding: '8px',
                  },
                }}
              />
            </Stack>
          </Stack>
          <FormButtonWrapper padding={2.5} spacing={1}>
            <Stack direction='row' spacing={2} width={'100%'} justifyContent={'flex-end'}>
              <Button type='submit' disabled={!dirty || !isValid} color='success' size='medium' fullWidth>
                Сохранить
              </Button>
              <Button onClick={() => props.onClose(dirty)} size='medium' fullWidth>
                Закрыть
              </Button>
            </Stack>
          </FormButtonWrapper>
        </Stack>
      </FormikProvider>
    </RemarkSecondaryFormWrapper>
  )
}

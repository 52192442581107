import { useGetProfileQuery } from 'api/profile'
import { Progress } from 'components/Progress'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { matchRoutes, useLocation } from 'react-router-dom'
import { ProfileState, updateProfileState } from 'store/slices/profile'
import { useAppDispatch } from 'store/store'

interface AppLayoutProps {
  children: ReactNode
}

const dirtyFormsUrl = [
  { path: 'administration/projects/add' },
  { path: 'administration/projects/edit/:projectId' },
  { path: 'administration/users/add' },
  { path: 'administration/users/edit/:userId' },
]

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const dirtyFormMatch = matchRoutes(dirtyFormsUrl, location)
  const routeMatch = dirtyFormMatch?.length && dirtyFormMatch[0].route.path

  const {
    data: profile,
    error: profileError,
    isLoading: profileIsLoading,
    isSuccess: profileIsSuccess,
  } = useGetProfileQuery()

  const value = useMemo<ProfileState>(() => {
    return {
      profile: profileError ? ({} as ProfileState['profile']) : profile || ({} as ProfileState['profile']),
      currentCompanyId: profile?.company.companyID || 0,
      isLogout: false,
    }
  }, [profile, profileError])

  const isLoading = profileIsLoading
  const isError = profileError

  useEffect(() => {
    if (profileIsSuccess) {
      dispatch(updateProfileState(value))
    }
  }, [profileIsSuccess])

  useEffect(() => {
    if (isError) {
      console.log('Ошибка')
    }
  }, [isError])

  // clear dirty form on other routes
  useEffect(() => {
    if (!routeMatch) {
      localStorage.setItem('dirtyForm', '')
    }
  }, [routeMatch])

  return isLoading ? <Progress /> : <>{children}</>
}

import { PrescriptionFilters } from '../../../../pages/Prescriptions/components/PrescriptionsTable'
import { FilterItem } from '../FilterItem'
import { FilterListWrapper } from './FilterList.styles'
import { FilterListProps } from './FilterList.types'
import { formateDate } from './FilterList.utils'
import { documentsApi } from 'api/documents'
import { PrescriptionFilterItem } from 'api/prescriptions/types'
import { RemarksFilters } from 'api/remarks/types'
import { Divider } from 'components/Divider'
import { Progress } from 'components/Progress'
import { FilterableColumn, rangingDates } from 'core/types/prescription'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'

export const FilterList = <T extends PrescriptionFilters | RemarksFilters>({
  searchValue,
  filterData,
  localFilterData,
  setLocalFilterData,
  setRangeFilterData,
  query,
  columnId,
  api,
  tagName,
  hasDateSelectionRange,
  range,
  isRangeApplied,

  filterType,
  isRangingDate,
}: FilterListProps<T>) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)

  const after = formateDate(range.dateStart, columnId)
  const before = formateDate(range.dateEnd, columnId)

  const test = () => {
    if (isRangingDate && (after || before)) {
      return { ...filterData, [`${columnId}_range`]: { after, before } }
    }

    return filterData
  }

  const { queryData, onScrollWithInfiniteLoad } = useInfiniteScroll({
    api,
    tagName,
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: query,
    arg: {
      projectId,
      query: searchValue || undefined,
      columnId,
      filter: test(),
    },
  })

  const { data = [], total } = queryData.data || {}
  const { isLoading } = queryData || {}

  const filtredList = useMemo(() => {
    const chosenTypes: PrescriptionFilterItem[] = []
    let unselectedTypes: PrescriptionFilterItem[] = []

    // должны взять массив из даты по ключу текущей columnId
    data.forEach((item: PrescriptionFilterItem) => {
      // @ts-ignore
      if ((localFilterData[columnId] || []).includes(item.id)) chosenTypes.push(item)
      else unselectedTypes.push(item)
    })

    if (isRangeApplied) {
      unselectedTypes = unselectedTypes.filter((value) => value.data?.chosen)
    }

    return {
      chosenTypes,
      unselectedTypes,
    }
  }, [data, localFilterData, isRangeApplied])

  const onCheckboxChange = (checked: boolean, item: PrescriptionFilterItem) => {
    if (checked) {
      // @ts-ignore
      const newArray = (localFilterData[columnId] || []).filter((itemId: string | number) => itemId !== item.id)

      setLocalFilterData({ ...localFilterData, [columnId]: newArray.length ? newArray : null })
    } else {
      setLocalFilterData({ ...localFilterData, [columnId]: [...(localFilterData[columnId] || []), item.id] })
    }
  }

  const renderFilterItem = (item: PrescriptionFilterItem) => {
    return (
      <FilterItem
        item={item}
        key={item.id}
        // @ts-ignore
        checked={!!(localFilterData[columnId] || []).includes(item.id)}
        onCheckboxChange={(checked, item) => onCheckboxChange(checked, item)}
        isAvailableCheckbox={filterType === 'list'}
      />
    )
  }

  return (
    <FilterListWrapper onScroll={onScrollWithInfiniteLoad} spacing={2}>
      {isLoading ? (
        <Progress />
      ) : (
        <>
          {filtredList.chosenTypes.length ? filtredList.chosenTypes.map((item) => renderFilterItem(item)) : null}

          {filtredList.chosenTypes.length && filtredList.unselectedTypes.length ? <Divider /> : null}

          {filtredList.unselectedTypes.map((item) => renderFilterItem(item))}
        </>
      )}
    </FilterListWrapper>
  )
}

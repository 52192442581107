import { validationProfile } from '../../UserManagement.validation'
import { UserAccesses } from './UserAccesses'
import { UserData } from './UserData/UserData'
import { UserFormData, UserFormProps } from './UserForm.types'
import { Stack } from '@mui/material'
import { UserProfile } from 'api/profile/types'
import { useCreateUserMutation, useInviteUserMutation, useUpdateUserAccessMutation } from 'api/users'
import { CreateUserRequest, CreateUserResponse, InviteUserResponse, UpdateUserAccessBody } from 'api/users/api.types'
import { SetAvatarData, UserBindCandidate, UserBindFields } from 'api/users/types'
import { FoundUserDialog } from 'components/FoundUserDialog'
import { DirtyFormForGlobalConfirm } from 'core/types/global'
import { Form, FormikProvider } from 'formik'
import { useForm } from 'hooks/useForm'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { DEFAULT_DISPLAY_PASSWORD_VALUE } from 'utils/constants'
import { mapFieldErrorByError } from 'utils/mapFieldErrorByError'

export const UserForm: React.FC<UserFormProps> = ({ userProfileToChange, isEditUser, onFormChange }) => {
  const { t } = useTranslation('user')
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const profile = useTypedSelector(profileSelector)
  const [candidates, setCandidates] = useState<CreateUserResponse['candidates']>()
  const companyId = profile.company.companyID

  const {
    avatar,
    firstName,
    lastName,
    middleName,
    id,
    email,
    role,
    login,
    phoneConfirmed,
    emailConfirmed,
    phone,
    company,
    access,
  } = userProfileToChange || {}

  const { companyName, userPosition, userCompanyName } = company || {}

  // @ts-ignore
  const initialValues: UserFormData = useMemo(() => {
    return {
      lastName: lastName || '',
      firstName: firstName || '',
      middleName: middleName || '',
      companyName: userProfileToChange?.company?.userCompanyName || '',
      position: userPosition || '',
      phone: phone || '',
      email: email || '',
      login: login || '',
      password: isEditUser ? true : '',
      // password: DEFAULT_DISPLAY_PASSWORD_VALUE as string | undefined,
      avatar: avatar || '',
      role: role || 'none',
      allProjects: access?.allProjects || false,
      projects: access?.projects || [],
      allCompanies: access?.projects?.filter((project) => project.allContractors).map((project) => project.id) || [],
      companies:
        access?.projects?.flatMap((project) => {
          return project.contractors?.map((contractor: any) => ({
            id: contractor.contractor?.id || contractor.company,
            name: contractor.contractor?.shortName || contractor.company,
            projectId: project.id,
            data: contractor.contractor?.id ? 'projectMember' : 'company',
          }))
        }) || [],
    }
  }, [lastName, firstName, middleName, userCompanyName, userPosition, phone, email, login, avatar, role, access])

  const [createFullUser, createFullUserResponse] = useCreateUserMutation()
  const [updateUserAccess, updateUserAccessResponse] = useUpdateUserAccessMutation()
  const [inviteUser, inviteUserResponse] = useInviteUserMutation()

  const onSubmit = useCallback(
    (values: UserFormData) => {
      const { companies, projects, role, allProjects, allCompanies } = values

      let dataForSetAccess: UpdateUserAccessBody | {} = {}

      if (role === 'contractor') {
        dataForSetAccess = {
          newRole: role,
          allProjects,
          access: projects.map((project) => {
            const filtredCompanies = companies
              .filter((company) => company.projectId === project.id && company.data === 'company')
              .map((company) => ({ company: company.name }))
            const filtredContractors = companies
              .filter((company) => company.projectId === project.id && company.data === 'projectMember')
              .map((company) => ({ contractor: company.id }))

            return {
              projectID: project.id,
              allContractors: allCompanies.includes(project.id),
              contractors: [...filtredCompanies, ...filtredContractors],
            }
          }),
        }
      } else {
        dataForSetAccess = {
          newRole: role,
          allProjects,
          access: projects.map((project) => ({
            projectID: project.id,
          })),
        }
      }

      if (role === 'contractor' && projects.length) {
        if (
          projects.filter(
            (project) =>
              !allCompanies.includes(project.id) && !companies.some((company) => company.projectId === project.id),
          ).length
        ) {
          const projectsWithoutAccess: string[] = []
          projects
            .filter(
              (project) =>
                !allCompanies.includes(project.id) && !companies.some((company) => company.projectId === project.id),
            )
            .map((project) => {
              projectsWithoutAccess.push(String(project.id))
            })
          setErrors({ companies: projectsWithoutAccess })
          return
        }
      }

      if (isEditUser) {
        updateUserAccess({
          body: dataForSetAccess as UpdateUserAccessBody,
          userId: userProfileToChange.id!,
          companyId,
        })
      } else {
        const { login, email, phone, password, avatar, companyName, position, firstName, lastName, middleName } = values

        const dataForCreate: CreateUserRequest = {
          profile: {
            email,
            login,
            password: password!,
            phone,
          },
          employment: {
            companyID: companyId,
            companyName,
            firstName,
            lastName,
            middleName,
            position,
          },
        }

        const dataForSetAvatar: SetAvatarData = {
          file: values.avatar as Blob,
        }

        createFullUser({
          companyId,
          dataForCreate,
          dataForSetAvatar,
          dataForSetAccess: dataForSetAccess as UpdateUserAccessBody,
        })
      }
    },
    [updateUserAccess, createFullUser],
  )

  const { formik } = useForm({
    validationSchema: validationProfile,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values)
      setTimeout(() => setSubmitting(false), 1000)
    },
  })

  const { values, setFieldValue, setFieldError, dirty, errors, setErrors } = formik

  // set global dirty for confirm dialogs on menu items click
  useEffect(() => {
    const dirtyFormType: DirtyFormForGlobalConfirm = dirty ? 'user' : ''
    localStorage.setItem('dirtyForm', dirtyFormType)
  }, [dirty])

  useEffect(() => {
    onFormChange(dirty)
  }, [dirty])

  useMutationHandlers(
    createFullUserResponse,
    (data: CreateUserResponse) => {
      if (!!data) {
        const { success: newUser, candidates } = data || {}

        if (!!newUser) {
          enqueueSnackbar('Профиль успешно добавлен.', { variant: 'success' })
          navigate('../users')
        }
        if (!!candidates?.length) {
          const notInvitedCandidates: UserBindCandidate[] = []
          candidates.forEach((candidate) => {
            const { alreadyInvited, bindFields } = candidate
            if (alreadyInvited) {
              bindFields?.forEach((field: UserBindFields) => setFieldError(field, t('status.coincidence')))
            } else {
              notInvitedCandidates.push(candidate)
            }
          })
          setCandidates(notInvitedCandidates)
        }
      }
    },
    (error) => {
      const errorData = mapFieldErrorByError(error)
      if (errorData) {
        const { field, text, type } = errorData
        if (type === 'phone') {
          setFieldError(field, t(text))
        }
      } else {
        enqueueSnackbar(t('common:errors.request_error'), { variant: 'error' })
      }
    },
  )

  useMutationHandlers(
    updateUserAccessResponse,
    (data: UserProfile) => {
      navigate('/administration/users')
      enqueueSnackbar('Профиль успешно изменен.', { variant: 'success' })
    },
    () => {
      enqueueSnackbar(t('common:errors.request_error'), { variant: 'error' })
    },
  )

  const handleCloseFoundUserDialog = useCallback(() => {
    setCandidates(undefined)
  }, [])

  const handleInviteUser = useCallback(
    (candidateId: UserProfile['id']) => {
      const selectedCandidate = candidates?.find((candidate) => candidate?.profile?.id === Number(candidateId))
      const getFieldBySelectedCandidate = (bindField: UserBindFields) => {
        if (selectedCandidate?.bindFields?.includes(bindField)) {
          return values[bindField] || ''
        }
        return ''
      }

      inviteUser({
        userId: candidateId,
        employment: {
          companyID: companyId,
          companyName: values.companyName,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          position: values.position,
        },
        profile: {
          email: getFieldBySelectedCandidate('email'),
          login: getFieldBySelectedCandidate('login'),
          phone: getFieldBySelectedCandidate('phone'),
          password: values.password!,
        },
      })
    },
    [inviteUser, values, candidates, companyId],
  )

  useMutationHandlers(
    inviteUserResponse,
    (data: InviteUserResponse) => {
      navigate('../')
      enqueueSnackbar(t('success.acceptInvitation'), { variant: 'success' })
    },
    (error) => {
      enqueueSnackbar(t('common:errors.request_error'), { variant: 'error' })
    },
  )

  return (
    <>
      <FormikProvider value={formik}>
        <Stack flex={1} component={Form} alignItems='center' px={7} py={4}>
          <Stack
            gap={'60px'}
            direction='row'
            flexWrap='wrap'
            justifyContent='center'
            style={{ maxWidth: '1072px', width: '100%' }}
          >
            <UserData
              values={values}
              isEditUser={isEditUser}
              setFieldValue={setFieldValue}
              initialValues={initialValues}
              phoneConfirmed={phoneConfirmed || false}
              emailConfirmed={emailConfirmed || false}
              name={userProfileToChange?.company?.userCompanyName}
            />
            <UserAccesses isEditUser={isEditUser} />
          </Stack>
        </Stack>
      </FormikProvider>
      <FoundUserDialog
        isOpen={!!candidates?.length}
        onCancel={handleCloseFoundUserDialog}
        candidates={candidates!}
        onSuccess={handleInviteUser}
      />
    </>
  )
}

import { Stack, TextField } from '@mui/material'
import { UploadDrawerFormWrapper } from 'components/UploadDrawer/UploadDrawerForm'
import styled from 'styled-components'

interface IBindRemarkInfoProps {
  isChecked: boolean
  isDisabled: boolean
}

export const BindRemarksFormWrapper = styled(UploadDrawerFormWrapper)`
  height: 100%;
  padding: 10px 0 0 20px;
  overflow: hidden;
`

export const BindRemarksSearch = styled(TextField)`
  width: 100%;
  padding-right: 20px;

  & .MuiInputBase-formControl {
    padding: 10px 8px;
  }

  & input {
    padding: 0;

    &::placeholder {
      color: ${(props) => props.theme.palette.secondary.gray};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.bg.gray};
    border-radius: 4px;
  }

  & .search-clear {
    padding: 4px;
  }
`

export const BindRemarkInfo = styled(Stack)<IBindRemarkInfoProps>`
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.bg.shades};
  padding: 6px 12px;
  border-radius: 4px;

  ${(props) =>
    props.isChecked &&
    `
    outline: 1px solid ${props.theme.palette.primary.main};
    background-color: #EDF4FC;
  `}
  ${(props) =>
    props.isDisabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}


  & p:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

import { RemarkMediaComments, RemarkStatuses, RemarkStatusesRu, RemarksFilters } from 'api/remarks/types'
import { CustomSelectValue } from 'components/CustomSelect/CustomSelect.types'
import { FileWithId, Media } from 'core/types/global'
import { PrescriptionFormData } from 'pages/Prescriptions/components/PrescriptionsForm/PrescriptionsForm.types'
import { CurrentSortableColumn, FilterData } from 'pages/Prescriptions/components/PrescriptionsTable'

export interface RemarkFormData {
  status: RemarkStatuses
  dateStart: Date | null
  number: string
  object: CustomSelectValue | null
  activityType: CustomSelectValue | null
  measures: string
  basis: string
  description: CustomSelectValueWithType | null
  comments: string

  contractor: CustomSelectValueWithType | null
  responsible: CustomSelectValueWithType | null
  dateEnd: Date | null
  dateDone: Date | null
  prescriptionNumber: string
  prescriptionDate: string
  notificationsEnabled: boolean
  copyNumber: number | null
  media: Media[]
  mediaIdsToDelete: number[]
  mediaForCreate: FileWithId[]
  mediaCommentById: null | RemarkMediaComments
  newMediaCommentById: null | RemarkMediaComments
  previousDateEnd: Date | null
}

export interface RemarkLocationState {
  sortableColumn: CurrentSortableColumn
  filterData: RemarksFilters
  prescriptionValues?: PrescriptionValuesWithId
  currentPage?: number
  prescriptionsPage?: number
  shouldResetLocationState?: boolean
}

export interface PrescriptionValuesWithId extends PrescriptionFormData {
  id: number
}

export interface CustomSelectValueWithType extends CustomSelectValue {
  type: string
}

export type RemarkDrawerType = 'elimination' | 'remarkSecondary'

export type RemarkFormDialogTrigger =
  | 'deletePrimary'
  | 'deleteSecondary'
  | 'exit'
  | 'submit'
  | 'eliminationExit'
  | 'notification'
  | 'remarkSecondaryExit'
  | 'autofillDescription'
  | 'autofillMeasuresAndBasis'

export interface RemarkAutofilledData {
  object: CustomSelectValue | null
  contractor: CustomSelectValueWithType | null
}

export const remarkModalNotifyInfo = 'Уведомление о смене статуса будет направлено на e-mail представителей подрядчика.'
export const remarkNotificationInfo =
  'Уведомления о смене статуса устранения будут направлены на e-mail автора предписания. Если  уведомления о смене статуса устранения по данному замечанию не нужны, переведите переключатель в неактивное положение.'
export const remarkSecondaryInfo =
  'В связи с неустранением направленного замечания в  установленный срок доступно создание повторного замечания для направления в адрес подрядчика в составе нового предписания.'
